<template>
   <div class="card cursor-pointer" :index="index" v-if="showSelect" @click="select">
      <div class="card-img overflow-hidden">
         <img :src="produto.banner == null ? '' : produto.banner" class="card-img-top w-100" alt="icon" @error="imageError" />
      </div>
      <div class="card-body px-12 py-2">
         <h2 class="font-13 mb-1 limitador-3 text-capitalize" :title="produto.nome">{{ String(produto.nome).toLowerCase() }}</h2>
         <p class="font-11 mb-0 limitador-1 opacity-90">
            <span><i class="far fa-barcode font-10 me-1"></i>{{ produto.ean == null || String(produto.ean).trim().length == 0 ? 'Sem código' : produto.ean }}</span>
         </p>
         <p class="font-12 mb-0 limitador-1 opacity-90 text-end">
            <span><small>R$ </small>{{ produto.preco == null ? '0,00' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
         </p>
      </div>
   </div>

   <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''" v-else>
      <div class="card-body p-12">
         <div class="row align-items-center">
            <div class="w-max-content pe-12 d-none d-xxl-block">
               <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
            </div>
            <div class="col-sm text-center text-sm-start">
               <h1 class="font-14 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
               <p class="font-10 mb-1 limitador-1 text-secondary"><i class="far fa-ruler font-9 me-1"></i>{{ produto.unidade }}</p>
               <p class="font-12 mb-0 limitador-1">
                  <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1"> Código:</strong>
                  <span> {{ produto.codigo == null || String(produto.codigo).trim().length == 0 ? 'Sem código' : produto.codigo }}</span>
               </p>
            </div>
            <div class="wpx-100 text-center mx-auto mx-sm-0 mt-2 mt-sm-0">
               <input type="text" class="form-control input-sm" v-model="produto.quantidade" @blur="salvar" />
            </div>
            <div class="wpx-100 text-center mx-auto mx-sm-0 mt-2 mt-sm-0">
               <input type="text" class="form-control input-sm" v-model="produto.preco" @blur="salvar" />
            </div>
            <div class="wpx-115 text-center d-none d-xl-block">
               {{ produto.total == null ? '0,00' : parseFloat(produto.total).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </div>
            <div class="w-max-content text-center font-16 mx-auto mx-sm-0 mt-2 mt-sm-0">
               <div class="btn-icone text-red">
                  <span @click="remover" class="py-1 py-sm-2"><i class="far fa-trash"></i><small>Excluir</small></span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Produto',
   props: ['produto', 'index', 'showSelect'],
   watch: {
      'produto.quantidade': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': this.produto.isDecimal ? 3 : 0}).then((valor) => {
            this.produto.quantidade = valor
         })
      },
      'produto.preco': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.produto.preco = valor
         })
      }
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      salvar : function () {
         if (parseFloat(this.produto.preco) > 0 && parseFloat(this.produto.quantidade) > 0) {
            this.$emit('salvar', this.produto)
         }
      },
      select : function () {
         this.$emit('select', this.produto)
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   },
   mounted() {
      this.produto.quantidade = this.produto.isDecimal ? parseFloat(this.produto.quantidade).toFixed(3) : parseInt(this.produto.quantidade)
      this.produto.preco = this.produto.preco == null ? '0.00' : parseFloat(this.produto.preco).toFixed(2)
   }
}

</script>

<style scoped>

img {
   transition: all .3s ease-out 0s;
}

.card[index]:hover img {
   transform: scale(1.1) rotate(1deg);
}

h2 {
   height: 46.8px;
   line-height: 15.6px;
   word-break: break-all;
}

</style>