<template>
   <div class="row m-0 bg-white text-dark rounded">
      <div class="col-12 p-3" style="width: 350px; font-size: 12px;">
         <div class="row m-0 align-items-center text-center">
            <div class="col-12 px-1" style="font-size: 14px;">{{ String(fechamento.nomeLoja).toUpperCase() }}</div>
            <div class="col-12 px-1 mb-2">Número loja: {{ fechamento.numeroLoja }} - Caixa: {{ fechamento.nomePdv }}</div>
         </div>

         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="col-12 px-1 text-center">Detalhes do fechamento</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="col-4 px-1">Fiscal:</div>
            <div class="col-8 px-1 text-end text-truncate">{{ fechamento.fiscal }}</div>
            <div class="col-4 px-1">Operador(a):</div>
            <div class="col-8 px-1 text-end text-truncate">{{ fechamento.operador }}</div>
            <div class="col-4 px-1">Período:</div>
            <div class="col-8 px-1 text-end text-truncate">
               <span>{{ fechamento.dataInicio == null ? 'Desconhecido' : fechamento.dataInicio }} até </span>
               <span>{{ fechamento.dataFim == null ? 'Desconhecido' : fechamento.dataFim }}</span>
            </div>
            <div class="col-5 px-1">Saldo inicial:</div>
            <div class="col-7 px-1 text-end text-truncate">{{ parseFloat(fechamento.saldoInicial).toFixed(2) }}</div>
            <div class="col-5 px-1">Saldo final:</div>
            <div class="col-7 px-1 text-end text-truncate">{{ parseFloat(fechamento.saldoFinal).toFixed(2) }}</div>
            <div class="col-5 px-1">Saldo informado:</div>
            <div class="col-7 px-1 text-end text-truncate">{{ parseFloat(fechamento.saldoInformado).toFixed(2) }}</div>
            <div class="col-5 px-1">Vendas via dinheiro:</div>
            <div class="col-7 px-1 text-end text-truncate">{{ parseFloat(fechamento.totalVendasDinheiro).toFixed(2) }}</div>
            <div class="col-5 px-1">Vendas via débito:</div>
            <div class="col-7 px-1 text-end text-truncate">{{ parseFloat(fechamento.totalVendasDebito).toFixed(2) }}</div>
            <div class="col-5 px-1">Vendas via crédito:</div>
            <div class="col-7 px-1 text-end text-truncate">{{ parseFloat(fechamento.totalVendasCredito).toFixed(2) }}</div>
            <div class="col-5 px-1">Vendas via PIX:</div>
            <div class="col-7 px-1 text-end text-truncate">{{ parseFloat(fechamento.totalVendasPix).toFixed(2) }}</div>
            <div class="col-5 px-1">Vendas via convênio:</div>
            <div class="col-7 px-1 text-end text-truncate">{{ parseFloat(fechamento.totalVendasConvenio).toFixed(2) }}</div>
            <div class="col-5 px-1">Vendas via outros meios:</div>
            <div class="col-7 px-1 text-end text-truncate">{{ parseFloat(fechamento.totalVendasOutrosMeios).toFixed(2) }}</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center" style="font-size: 11.5px;">
            <div class="col-4 px-1 text-truncate">Data</div>
            <div class="col-4 px-1 text-truncate">Tipo de transação</div>
            <div class="col-4 px-1 text-truncate text-end">Valor</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000 !important;">
         
         <div class="row m-0 align-items-center" style="font-size: 11.5px;" v-for="(t, index) in fechamento.transacoes" :key="index">
            <div class="col-4 px-1 text-truncate">
               <span>{{ t.data.dayOfMonth.toString().padStart(2, '0') +'/'+ t.data.monthValue.toString().padStart(2, '0') +'/'+ t.data.year }} </span>
               <span>{{ t.data.hour.toString().padStart(2, '0') +':'+ t.data.minute.toString().padStart(2, '0') }}</span>  
            </div>
            <div class="col-5 px-1 text-truncate">{{ String(t.formaPgto).replace(/_/g, " ") }}</div>
            <div class="col-3 px-1 text-truncate text-end">{{ parseFloat(t.valor).toFixed(2) }}</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
      </div>
   </div>
</template>

<script>

export default {
	name: 'ComprovanteFechamento',
	props: ['fechamento']
}

</script>